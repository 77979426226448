const CANDIDATE_NAV = [
  {
    name: "jobs",
    title: "Candidate Jobs",
    spread_children: true,
    children: [
      {
        name: "candidate.dashboard",
        title: "Dashboard",
        link: "/dashboard"
      },
      {
        name: "candidate-jobs.index",
        title: "Find Jobs",
        link: "/candidate-jobs"
      },
    ]
  }
];
const EMPLOYER_NAV = [
  {
    name: "jobs",
    title: "Employer Jobs",
    spread_children: true,
    children: [
      {
        name: "employer.dashboard",
        title: "Dashboard",
        link: "/employer/dashboard"
      },
      { name: "jobs.index", title: "All Jobs", link: "/jobs" },
      { name: "jobs.new", title: "New job", link: "/job/new", permission_required: "job.create" }
    ]
  }
];
const HR_TEAM_NAV = [
  {
    name: "hr_team",
    title: "HR_Team Employers",
    spread_children: true,
    children: [
      {
        name: "hr_team.dashboard",
        title: "Dashboard",
        link: "/hr_team/dashboard"
      },
      { name: "company", title: "Companies", link: "/company" },
      { name: "hr_jobs.index", title: "Jobs", link: "/all_jobs" },
      { name: "hr_jobs.create", title: "Create job", link: "/job/create"}
    ]
  }
];
const ADMIN_NAV = [
  {
    name: "admin",
    title: "Admin",
    spread_children: true,
    children: [
      { name: "admin.dashboard", title: "Dashboard", link: "/admin" },
      { name: "admin.jobs", title: "Jobs", link: "/admin/jobs" },
      {
        name: "admin.candidates",
        title: "Candidates",
        link: "/admin/candidates"
      },
      {
        name: "admin.employers",
        title: "Employers",
        link: "/admin/employers"
      }
    ]
  }
];
const PROFILE_NAV = [
  {
    name: "profile",
    title: "Profile",
    children: [
      { name: "profile.view", title: "Edit Info", link: "/profile" },
      { name: "settings.basic", title: "Settings", link: "/settings/basic" },
      {
        name: "profile.logout",
        title: "Logout",
        link: null,
        function: "logout",
        type: "button",
        classes: " btn-error btn-outline"
      }
    ]
  }
];

export { ADMIN_NAV, CANDIDATE_NAV, EMPLOYER_NAV, PROFILE_NAV, HR_TEAM_NAV };
